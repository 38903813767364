:root {
  /* Step 0: 100.00px → 280.00px */
  --title-404-font: clamp(12rem, calc(-9.99rem + 33.83vw), 18rem);
  /* Step 0: 22.00px → 28.00px */
  --sub-title-404-font: clamp(1.25rem, calc(0.83rem + 1.13vw), 1.75rem);
}

@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap');

@import 'themeFiles/variables';

@import '~bootstrap/scss/bootstrap.scss';

:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #e2e2e2;
  -webkit-text-fill-color: #717171;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}
body {
  margin: 0;
  display: flex;
  color: #000;
  min-height: 100vh;
  line-height: 1.5;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Cabin', Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}
#root {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.loginPage {
  background: #f5f5f5 url('../assets/images/bg-login.png') no-repeat center
    center;
  background-size: cover;
}
.logInContent {
  max-width: 490px;
  background: #fff;
  > p {
    color: #000;
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
    background: #f4f7fb;
  }
}
.logInContentHead {
  padding: 1rem;
  color: $white;
  text-align: center;
  background: #1d2541;
  span {
    padding-bottom: 24px;
  }
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 1rem;
    font-family: 'Cabin';
    letter-spacing: 0.015em;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: #a5abc3;
    line-height: 30px;
    margin: -10px 0 0;
  }
}
.btn-google {
  padding: 2rem 1rem;
  .btn {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.25px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 8px;
    }
  }
}
.header {
  padding-top: 44px;
}
.topbar {
  z-index: 99;
  min-height: 44px;
  padding: 6px 24px 6px 120px;
  border-bottom: 1px solid #e2e2e2;
  @include transition(all 300ms ease-in-out);
}
.btn-menu {
  border: 0;
  border-radius: 0;
  background-color: transparent;
}
.sidebarMenu {
  top: 0;
  left: 0;
  width: 6.8rem;
  height: 100%;
  z-index: 999;
  position: fixed;
  padding: 1rem 0 0;
  background: #1d2541;
  @include transition(all 300ms ease-in-out);
  > span {
    margin-bottom: 32px;
  }
}
.fullView {
  .sidebarMenu {
    width: 13rem;
  }
  .topbar {
    padding-left: 226px;
  }
  &.header {
    + main {
      padding-left: 226px;
    }
  }
}
nav {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  .fullView & {
    font-size: 16px;
    padding: 0 1rem;
    font-weight: 500;
  }
  .nav-item {
    padding-top: 2rem;
    &:first-child {
      padding-top: 0;
    }
  }
  .nav-pills .nav-link.active {
    color: #1d2541;
    background: #fff;
    border-radius: 0px;
  }
  .nav-link {
    color: #fff;
    padding: 10px;
    flex-direction: column;
    @include transition(all 200ms ease-in-out);
    &:focus,
    &:hover {
      color: #1d2541;
      background-color: #fff;
      border-radius: 0px;
    }
    span {
      font-weight: 500;
      &:first-child {
        margin-bottom: 10px;
        .fullView & {
          padding-bottom: 0;
          padding-right: 8px;
        }
      }
    }
    .fullView & {
      flex-direction: row;
    }
  }
}
.dashboard {
  padding: 24px 24px 24px 120px;
  @include transition(all 300ms ease-in-out);
}
.pageHead {
  margin: 0 0 24px;
}
.pageHead .title {
  display: flex;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
}
.kpiBox {
  color: #2c2828;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 24px;
  padding: 1.25rem 1.75rem;
  background-color: #fff;
}
.kpiBox h2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
}
.kpiBox h3 {
  font-size: 26px;
  color: #29304c;
  font-weight: 700;
  line-height: 32px;
}
.kpiBox p {
  font-weight: 600;
}
.rmsDetail,
.prfCreate,
.prfList {
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  .form-control {
    height: 40px;
    font-size: 14px;
    color: #717171;
    font-weight: 600;
    padding-left: 30px;
    padding: 0.5rem 0.75rem;
    background-color: #f5f5f5;
    border: 1px solid #e2e2e2;
  }
}
.prfListTitle {
  padding: 1.5rem;
  border-bottom: 1px solid #e2e2e2;
  h2 {
    color: #000;
    margin: 0 0 8px;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
  }
  p {
    margin: 0;
    font-size: 1rem;
    color: #7081b9;
    line-height: 1.2;
  }
}
.prfTableFilters {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #e2e2e2;
  span {
    font-weight: 500;
  }
  select {
    width: 60px;
    height: 24px;
    color: #000;
    font-size: 12px;
    padding: 4px 6px;
    line-height: 15px;
    border-radius: 4px;
    border: 1px solid #e2e2e2;
  }
  .formSearch {
    > div {
      display: flex;
    }
    label {
      font-weight: 500;
      padding-right: 8px;
    }
    .form-control {
      width: 300px;
      height: 32px;
      border-color: #e2e2e2;
      border-radius: 4px;
      padding: 4px 12px;
      background-color: $white;
    }
  }
}
.tabelHolder {
  padding: 1.5rem;
  table {
    white-space: nowrap;
    border-color: #e2e2e2;
    thead {
      th {
        padding: 1rem;
        font-weight: 800;
        font-size: 16px;
        background: #1d2541;
        color: #fff;
        box-shadow: rgb(24 30 53 / 20%) 0.5px -2.5px 3px;
        border-color: transparent;
        border-right: 1px solid #fff;

        img,
        svg {
          cursor: pointer;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &:first-child {
          td {
            padding-top: 10px;
          }
        }
      }
      td {
        padding: 5px 0;
        font-size: 14px;
        color: #1f263e;
        line-height: 1.2;
        vertical-align: middle;
        //box-shadow: rgb(24 30 53 / 20%) 0.5px -2.5px 3px;
        border-radius: 0;
        border-color: transparent;
        white-space: pre-wrap;
        // height: 250px;
        word-wrap: break-word;
        .td_box {
          padding: 10px 1rem;
          min-height: 60px;
          display: flex;
          align-items: center;
          -webkit-box-shadow: 1px 0px 5px 1px rgb(183 178 178 / 64%);
          -moz-box-shadow: 1px 0px 5px 1px rgb(183 178 178 / 64%);
          box-shadow: 1px 0px 5px 1px rgb(183 178 178 / 64%);
          .info_icon {
            background: #fff;
            border-radius: 50%;
            border: 2px solid #fff;
            width: 20px;
            height: 20px;
            display: flex;
          }
        }
        &:first-child {
          .td_box {
            border-radius: 20px 0 0 20px;
          }
        }
      }
    }
  }
  &.profileTable,
  &.resumeTable,
  &.adminUserList {
    tbody {
      tr {
        &:hover {
          td {
            background: #18244ed9;
            color: #fff;
          }
        }
      }
      td {
        padding: 1rem;
      }
    }
  }
  &.adminUserList,
  &.resumeTable {
    .btn {
      border-radius: 50%;
      padding: 5px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Initiated
.status1 {
  td {
    .td_box {
      background: #1e7aed61;
    }
    &:first-child {
      .td_box {
        background: #1e7aed !important;
      }
    }
  }
}

// Forwarded
.status3 {
  td {
    .td_box {
      background: #f59c224d;
    }
    &:first-child {
      .td_box {
        background: #f59c22 !important;
      }
    }
  }
}

// Rejected
.status4 {
  td {
    .td_box {
      background: #f2304752;
    }
    &:first-child {
      .td_box {
        background: #f23047 !important;
      }
    }
  }
}

// Accepted
.status5 {
  td {
    .td_box {
      background: #aedcbade;
    }
    &:first-child {
      .td_box {
        background: #34a853 !important;
      }
    }
  }
}

// Submitted
.status6 {
  td {
    .td_box {
      background: #5068985e;
    }
    &:first-child {
      .td_box {
        background: #506898 !important;
      }
    }
  }
}

// Closed
.status7 {
  td {
    .td_box {
      background: #99a7b980;
    }
    &:first-child {
      .td_box {
        background: #99a7b9 !important;
      }
    }
  }
}

// Internal Hire
.status8 {
  td {
    .td_box {
      background: #8338ec47;
    }
    &:first-child {
      .td_box {
        background: #8338ec !important;
        //background: #2f7691 !important;
      }
    }
  }
}

.tabelHolder {
  table {
    tbody {
      tr {
        &:hover {
          td {
            .td_box {
              background: #18244ed9;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.dropdownmenu {
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate3d(16px, 45px, 0px);
}
.paginations {
  padding: 0 24px 1rem;
  > span {
    /* font-size: 1rem;
    list-style: 1.2; */
    font-weight: 500;
  }
}
.pagination {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  li {
    span {
      border-radius: 0 !important;
    }
    a {
      color: #000;
      background: $white;
      border-radius: 0 !important;
    }
  }
  .active {
    .page-link {
      color: #fff;
      background-color: $primary;
    }
  }
}
.toolbar-class {
  background-color: $white;
  border: 1px solid #dee8f4;
  .rdw-option-wrapper {
    min-width: auto;
    height: auto;
  }
}
.editor-class {
  padding: 0 1rem;
  min-height: 630px;
  background-color: $white;
  border: 1px solid #dee8f4;
}
.tabelHolder {
  .badge {
    padding: 5px 10px;
    line-height: 20px;
    border-radius: 2px;
    text-transform: capitalize;
    color: #fff !important;
    font-weight: 700;
    font-size: 16px;
    background: none !important;
  }
}
.rmsDetail {
  .positionDetailHead {
    border-top: 0;
  }
}
.positionDetailHead {
  padding: 1rem 1.5rem;
  border-top: 1px solid #e2e2e2;
  &:first-child {
    .accordion-body {
      padding-bottom: 0;
    }
  }
  .accordion-body {
    border: 1px solid #e2e2e2;
    border-top: 0;
  }
  .accordion-button,
  .accordion-button:not(.collapsed) {
    color: white;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0;
    background-color: #1d2541;
    .optional {
      font-size: 13px;
      margin: 0px 8px;
    }
  }
  .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  .rows {
    margin-right: 50px;
    .detailLabel {
      margin-top: 20px;
      font-family: Cabin;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 12px;
      margin-right: 5px;
    }
  }
  .errorMessage {
    font-weight: bold;
    color: red;
    font-size: 12px;
  }
  .postionDetail {
    height: 40px;
    /* font-size: 12px; */
    font-size: 14px;
    font-weight: 600;
    color: #717171;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    padding: 0.5rem 1.5rem 0.5rem 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43' fill='%23717171'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position-x: 103%;
    background-position-y: 12px;
  }
}

.internalHireDropdown {
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  padding: 0.5rem 1.5rem 0.5rem 8px;
  margin: 0px 0.75rem 0px 0.5rem;
  color: #717171;
}

.btn-createPrf {
  font-weight: 600;
  padding: 8px 13px;
  span {
    &:first-child {
      margin-top: -2px;
    }
  }
}
.notificationsDropodown {
  > button {
    font-size: 18px;
    &:after {
      display: none;
    }
    span {
      top: -6px;
      right: -5px;
      color: $white;
      font-size: 10px;
      min-width: 14px;
      font-weight: 600;
      line-height: 14px;
      position: absolute;
      border-radius: 50%;
      background: #f16e6e;
    }
  }
}
.userDropdown {
  > button {
    &:after {
      display: none;
    }
  }
  .dropdown-menu {
    border: 0;
    padding: 14px 10px;
    border-radius: 4px;
    background: #fff;
    min-width: 8.125rem;
    margin-top: 0.625rem !important;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
    button {
      span {
        &:last-child {
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
        }
      }
    }
  }
}
.errorMessage {
  font-weight: bold;
  color: red;
  font-size: 12px;
}
.postionDetail {
  width: 100%;
}
.cmtBtn {
  background-color: #1d2541;
  width: 10%;
  margin: 10px 0px;
}
.modal-content {
  width: 120%;
  .modelHeader,
  .cmtBtn:hover {
    background-color: #1d2541;
    color: white;
    .btn-close {
      background-image: url('../assets/images/icons8-close-30.png');
      background-size: 80% 80%;
    }
  }
  .modal-title {
    font-size: 16px;
  }
  .modelPopup {
    margin: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    textarea {
      resize: vertical;
      line-height: 40px;
      margin: 0 0 15px;
      border-radius: 5px;
      padding: 10px 14px;
    }
    .form-label {
      margin: 0 0 10px;
      font-weight: bold;
    }
    .optional {
      color: red;
    }
  }
  .largeButtons {
    margin: 20px 10px 10px 20px;
    display: flex;
    flex-direction: column;
    font-weight: bold;

    .form-label {
      margin: 0 0 10px;
      font-weight: bold;
    }
    .optional {
      color: red;
    }
  }
}
.cancelBtn,
.cancelBtn:hover {
  margin-right: 10px;
}
.commentSectionHead {
  padding: 1rem 1.5rem;
  border-top: 1px solid #e2e2e2;
  .commentHeading {
    margin: 0;
    color: white;
    font-size: 14px;
    background-color: #1d2541;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px 10px 10px 30px;
  }
  .cmtsection {
    display: flex;
    padding: 24px;
    border: solid #e2e2e2;
    border-width: 0 1px 1px;
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
    .profilePic {
      background-color: #e4eefe;
    }
  }
  .userName {
    background-color: #e4eefe;
    padding: 10px;
    border-radius: 50%;
    margin-bottom: 58px;
    margin-top: 15px;
  }
  .cmtuser {
    margin-left: 15px;
    .datetime {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #1d2541;
    }
    .cmtPgh {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 2px;
    }
    .cmtHead {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 4px;
      span.Roless {
        font-size: 14px;
        color: #7081b9;
      }
    }
  }
}
.nav-tabs {
  border-bottom: 0;
  padding: 0 1.5rem;
  border-top: none;
  .nav-item {
    border: 0;
    padding: 0 0.5rem 0 0;
    .nav-link {
      border: 0;
      font-size: 16px;
      color: #7081b9;
      font-weight: 600;
      line-height: 19px;
      padding: 12px 30px;
      background: #f0f2f7;
      &:hover {
        color: #fff;
        background: #266bec;
      }
    }
    .active {
      color: #fff;
      background: #266bec;
    }
  }
}
.textEditorContainer {
  .editorHolder {
    height: 318px;
    margin-bottom: 1rem;
    &:last-child {
      margin: 0;
    }
    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 1rem;
    }
  }
  .textEditor {
    height: 240px;
    // height: 277px;
    .ql-container {
      font-size: 14px;
    }
  }
}
.infoBar {
  border-radius: 6px;
  margin-bottom: 24px;
  background-color: #f0f2f7;
  span {
    font-size: 14px;
    font-weight: 400;
    color: #525252;
    line-height: 1.2;
  }
  strong {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
  }
}
.prfSteps {
  padding: 1px 1.5rem 1rem;
}
.prfStep {
  color: $black;
  font-size: 14px;
  font-weight: 600;
  min-width: 190px;
  line-height: 17px;
  margin: 0 1rem 0 0;
  position: relative;
  background: #f0f2f7;
  padding: 10px 4px 10px 30px;
  span {
    strong {
      &:first-child {
        font-size: 12px;
        padding-bottom: 4px;
      }
    }
  }
  &:after {
    top: 0;
    left: 100%;
    content: '';
    position: absolute;
    border-top: 29px solid transparent;
    border-left: 16px solid #f0f2f7;
    border-bottom: 29px solid transparent;
  }
  &::before {
    top: 0;
    content: '';
    position: absolute;
    right: calc(100% - 16px);
    border-left: 16px solid white;
    border-top: 29px solid transparent;
    border-bottom: 29px solid transparent;
  }
}
.detailLabel {
  color: #000;
  margin: 0 0;
  font-size: 14px;
  font-weight: 400;
  // line-height: 20px;
}
.accordion-body {
  .form-control {
    background-color: $white;
  }
}
.inputHolder {
  // .form-control {
  padding-left: 19px;
  // }
  .dollar {
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    font-size: 12px;
    color: #717171;
    position: absolute;
    padding: 12px 2px 15px 23px;
  }
}
.step-styles {
  clip-path: polygon(94% 0, 100% 50%, 94% 100%, 0% 100%, 6% 50%, 0% 0%);
  margin-top: 15px;
}
.gridContainerSkills {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Five columns */
  gap: 10px; /* Adjust the gap as needed */
  padding: 0;
  margin: 0;
}
.gridContainerT8R {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 10px; /* Adjust the gap as needed */
  padding: 0;
  margin: 0;
}
.totalCandidates {
  margin-top: 15px;
  margin-bottom: 20px;
}
.columns-question {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(480px, 1fr)
  ); /* Creates a responsive grid layout */
  gap: 16px; /* Adds spacing between grid items */
  list-style-type: none; /* Removes default list styling */
  margin: 0; /* Adjusts the margin as needed */
}
.arrow-content {
  height: 58px;
  font-size: 15px;
  min-width: 200px;
  font-weight: 600;
  line-height: 17px;
  font-family: Cabin;
  font-style: normal;
  padding: 0 25px 0 20px;
  strong {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: capitalize;
    padding-right: 10px;
    &:first-child {
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.004em;
    }
  }
}
.breadcrumb-item {
  &.active {
    color: #7081b9;
  }
}
.nocommentsData {
  padding: 30px 0;
  img {
    margin-bottom: 1rem;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    color: #7081b9;
    line-height: 19px;
  }
}
.modelHeader .btn-close {
  opacity: 1;
  width: 0.5em;
  height: 0.5em;
}
.noPRF {
  padding-top: 6rem;
  padding-bottom: 6rem;
  p {
    font-size: 16px;
    margin: 1.5rem 0 0;
    font-weight: 600;
    color: #7081b9;
    line-height: 19px;
  }
}
.breadcrumb-style {
  color: #000000;
  text-decoration: none;
}
.userDetail {
  h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    padding: 10px 24px;
    background: #1d2541;
  }
  /* label {
    font-size: 12px;
    color: #8c8c8c;
    line-height: 20px;
  } */
  .form-control,
  p {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}
.btn-clear {
  top: 5px;
  right: 5px;
  z-index: 0;
  font-size: 17px;
  cursor: pointer;
  position: absolute;
}
.ql-disabled div[contenteditable='false'],
.positionDetailHead select:disabled,
.userDetail .postionDetail:disabled,
.form-control:disabled {
  color: #686868;
  background: #f1f1f1;
  border: 1px solid #e2e2e2;
}
label + br {
  display: none;
}
label,
.detailLabel-control {
  color: $black;
  display: block;
  padding: 0 0 4px;
  line-height: 1.5;
}
.dash {
  top: 0;
  left: 50%;
  z-index: 2;
  width: auto;
  padding: 0 6px;
  font-size: 24px;
  position: absolute;
  margin: 11px 0 0 -11px;
}
.status-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.5rem; /* More spacing between items */
  font-family: 'Helvetica Neue', Arial, sans-serif; /* Use a modern font */
  padding: 1rem; /* Padding around the container */
  background-color: #f9f9f9; /* Light background for the container */
  border-radius: 8px; /* Rounded corners for the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.status-container span {
  font-weight: bold;
  margin-right: 0.5rem;
  font-size: 1.2rem; /* Larger font size for the label */
}

.status-container label {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #ccc; /* Slightly thicker border */
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease,
    box-shadow 0.3s ease;
}

.status-container input[type='checkbox'] {
  margin-right: 0.5rem;
  accent-color: #007bff;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.status-container label:hover {
  background-color: #e0e0e0;
  border-color: #007bff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow on hover */
}

.status-container input[type='checkbox']:checked + label {
  background-color: #d1e7fd;
  border-color: #007bff;
}

.status-container input[type='checkbox']:checked + label {
  background-color: #007bff; /* Change background color when checked */
  color: #ffffff; /* Change text color when checked */
}

.status-container label:active {
  transform: scale(0.98); /* Slightly scale down on click */
}
.userDetail {
  .postionDetail {
    color: #000;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    padding: 0.5rem 1.5rem 0.5rem 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43' fill='%23717171'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position-x: 103%;
    background-position-y: 12px;
  }
}
.pagination {
  .break {
    a {
      width: 34px;
      height: 29px;
      display: block;
      padding-bottom: 4px;
      line-height: 25px;
      text-align: center;
      border-top: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
    }
  }
  .next {
    .page-link {
      border-radius: 0 4px 4px 0 !important;
    }
  }
  .previous {
    .page-link {
      border-radius: 4px 0 0 4px !important;
    }
  }
  .next,
  .previous {
    .page-link {
      color: $white;
      background: $primary;
      border-color: $primary;
    }
    &.disabled {
      .page-link {
        color: $gray-600;
        background: $gray-300;
        border-color: $gray-300;
      }
    }
  }
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingContainer {
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
button#Acceptprf-btn {
  color: #34a853;
}
.prf-btn {
  color: #000;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
.prf-btn + .prf-btn {
  color: #34a853;
}
.btn {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 0.5rem 1rem;
}
.btn-danger {
  background: #f16e6e;
  border-color: #f16e6e;
}
.employee {
  background: #ebf6ee;
}
.employeeBanner {
  width: 46%;
  padding: 20px 26px;
  background: linear-gradient(199.07deg, #0c60ff 0.32%, #003aa6 100%);
}
.employeeContent {
  .contentArea {
    width: 100%;
    height: 640px;
    padding: 40px;
    margin: 0 auto;
    max-width: 640px;
    flex-wrap: wrap;
    figure {
      margin-bottom: 24px;
    }
    h1 {
      color: #000;
      font-size: 26px;
      margin: 0 0 1rem;
      font-weight: 600;
      line-height: 35px;
    }
    p {
      margin: 0 4rem;
      font-size: 20px;
      color: #5e5e5e;
      line-height: 24px;
      text-align: center;
    }
  }
  .contentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 90vh;
  }
}
.btn-logout {
  color: #f16e6e;
  &:hover {
    color: #f16e6e;
  }
}
.themeDropDown {
  top: 2rem;
  right: 2rem;
  z-index: 10;
  position: absolute;
  .dropdown-menu {
    overflow: hidden;
    padding: 0;
    min-width: auto;
    .dropdown-item {
      padding: 0.75rem 1rem;
      &:active {
        background-color: var(--bs-dropdown-link-hover-bg);
      }
    }
  }
}
button#dropdown-basic {
  background: white;
  border: none;
  &:hover {
    opacity: 0.8;
  }
}
.btnCancel {
  background: none;
  padding: 0;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
.btnCancel:hover {
  background: none;
  color: #000;
}
.uploadArea {
  margin: 0 -20px;
  padding: 0 20px 20px;
  border-bottom: 1px solid #dee8f4;
}
.uploadList {
  padding: 6px;
  overflow: hidden;
  max-width: 300px;
  border-radius: 8px;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  .imgHolder {
    border-radius: 8px;
    overflow: hidden;
  }
  .btn-actions {
    opacity: 0;
    z-index: 10;
    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    background-color: rgba(#1d2541, 0.8);
    @include transition(all 300ms ease-in-out);
  }
  &:hover {
    .btn-actions {
      opacity: 1;
      visibility: visible;
    }
  }
  a {
    text-decoration: none;
    color: black;
    text-align: center;
    font-weight: bold;
    display: block;
    span {
      margin-left: 1pc;
    }
  }
}

.fileUpload {
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  align-items: center;
  max-width: 550px;
  input {
    visibility: hidden;
    position: absolute;
  }
  > div {
    h6 {
      background-color: #0d6efd;
      color: white;
      padding: 10px 20px 10px 20px;
      border: 1px solid #0d6efd;
      font-size: 14px;
      border-radius: 6px;
      margin-top: 10px;
      transition: 0.1s;

      &:hover {
        background-color: #0069d9;
        transition: 0.1s;
      }
    }
  }
}

.themeFileUpload {
  margin: 0 auto 1rem;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  padding: 1rem 2rem;
  border-radius: 8px;
  position: relative;
  align-items: center;
  max-width: 550px;
  gap: 1.5rem;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  input {
    top: 200%;
    left: 200%;
    visibility: hidden;
    position: absolute;
  }
  > div {
    gap: 0.5rem;
    h6 {
      background-color: white;
      color: blue !important;
      border: 2px solid blue;
      border-radius: 4px;
      padding: 10px 20px 10px 20px;
      margin-top: 5px;
    }
    span {
      font-size: 16px;
      color: #000;
      &:last-child {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
.filters {
  padding: 1.125rem 0;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
.statistics {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  border-radius: 5px;
  background: #fff;

  .statistic {
    border: 0;
    display: flex;
    padding: 0.75rem;
    border-radius: 8px;
    flex-direction: column;

    &.active {
      background: #1d2541;
      color: white;
      border-color: #1d2541;
    }

    h2 {
      flex-grow: 1;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 0.75rem;
    }
    h3 {
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 0;
      line-height: 1.25;
    }
  }

  .statistic:hover .info_icon svg path {
    fill: white;
  }

  .alert-success {
    color: #34a853;
    background: rgba(#34a853, 0.1);
    border: 1px solid #34a853;
  }
  .alert-primary {
    color: #0f62fe;
    background: rgba(#0f62fe, 0.1);
    border: 1px solid #0f62fe;
  }
  .alert-warning {
    color: #ed893e;
    background: rgba(#ed893e, 0.1);
  }
  .alert-danger {
    color: #f16e6e;
    background: rgba(#f16e6e, 0.1);
  }

  .Total_Accepted_PRFs {
    color: #34a853;
    border: 1px solid #34a853;
    background: rgba(#34a853, 0.1);

    &:hover {
      background: #1d2541;
      color: white;
      border-color: #1d2541;
      cursor: pointer;
      transition: 0.5s;
    }
  }
  .Total_New_PRFs {
    color: #0f62fe;
    background: #e4eefe;
    border: 1px solid #0f62fe;
    &:hover {
      background: #1d2541;
      color: white;
      border-color: #1d2541;
      cursor: pointer;
      transition: 0.5s;
    }
  }
  .Average_Forwarded_Time {
    color: #ed893e;
    border: 1px solid #ed893e;
    background: rgba(#ed893e, 0.1);
  }
  .Average_Submitted_Time {
    background: rgba(110, 223, 246, 0.15);
    border: 1px solid #6edff6;
    color: #6edff6;
  }
  .Total_Rejected_PRFs {
    color: #f16e6e;
    background: rgba(#f16e6e, 0.11);
    border: 1px solid #f16e6e;
    &:hover {
      background: #1d2541;
      color: white;
      border-color: #1d2541;
      cursor: pointer;
      transition: 0.5s;
    }
  }
  .Total_PRFs {
    color: #1d2541;
    border: 1px solid #1d2541;
    background: rgba(#1d2541, 0.15);
    &:hover {
      background: #1d2541;
      color: white;
      border-color: #1d2541;
      cursor: pointer;
      transition: 0.5s;
    }
  }
  .Total_Active_PRFs {
    color: #1d2541;
    border: 1px solid #1d2541;
    background: rgba(#1d2541, 0.15);
    &:hover {
      background: #1d2541;
      color: white;
      border-color: #1d2541;
      cursor: pointer;
      transition: 0.5s;
    }
  }
  .Average_Closure_Time {
    color: #f8b00a;
    border: 1px solid #f8b00a;
    background: rgba(#f8b00a, 0.15);
  }
  .Total_Closed_PRFs {
    color: #7081b9;
    border: 1px solid #7081b9;
    background: rgba(#7081b9, 0.1);
    &:hover {
      background: #1d2541;
      color: white;
      border-color: #1d2541;
      cursor: pointer;
      transition: 0.5s;
    }
  }
  .Total_Forwarded_PRFs {
    color: #ec7112;
    border: 1px solid #ed893e;
    background: rgba(#dd813c, 0.1);
    &:hover {
      background: #1d2541;
      color: white;
      border-color: #1d2541;
      cursor: pointer;
      transition: 0.5s;
    }
  }
  .Total_Internal_Hire_Closed {
    color: #7081b9;
    border: 1px solid #7081b9;
    background: rgba(#7081b9, 0.1);
  }
  .Average_Acceptance_Time {
    color: #8040a6;
    border: 1px solid #8040a6;
    background: rgba(#8040a6, 0.1);
  }
  .Average_PRF_Resubmission_Time {
    color: #a64073;
    border: 1px solid #8040a6;
    background: rgba(#8040a6, 0.1);
  }
  .Total_Submitted_PRFs {
    color: #192740;
    border: 1px solid #5068985e;
    background: rgba(#48649b, 0.1);
    &:hover {
      background: #1d2541;
      color: white;
      border-color: #1d2541;
      cursor: pointer;
      transition: 0.5s;
    }
  }
  .Total_Internal_Hires_In_Progress {
    color: #34804d;
    border: 1px solid #70b98c;
    background: rgba(#83b970, 0.1);
    &:hover {
      background: #1d2541;
      color: white;
      border-color: #1d2541;
      cursor: pointer;
      transition: 0.5s;
    }
  }
}
.filterList {
  padding: 4px;
  gap: 0 1.5rem;
  border-radius: 5px;
  background: #f4f5f7;
  li {
    button {
      font-size: 16px;
      font-weight: 700;
      color: #1d2541;
      line-height: 16px;
      padding: 12px 24px;
      border-radius: 5px;
      background: #f4f5f7;
      letter-spacing: 0.16px;
      border-color: #f4f5f7;
      @include media-breakpoint-down(lg) {
        padding: 12px 18px;
      }
      &.active,
      &:hover {
        color: $white;
        background: #1d2541;
        border-color: #1d2541;
      }
    }
  }
}
.prfAccountData,
.prfDepartmentData {
  height: 450px;
  padding: 12px;
  margin-bottom: 32px;
  border-radius: 5px;
  background: #fff;
  //box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.10);
  box-shadow: 0px 3.8037099838256836px 9.509275436401367px 0px
    rgba(0, 0, 0, 0.1);
  .noDataFound {
    p {
      color: #7081b9;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      margin: 1.5rem 0 0;
    }
  }
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Cabin;
  }
}
.filters {
  .dateArea {
    position: relative;
    input {
      border: 0;
      width: 250px;
      height: 44px;
      text-align: right;
      border-radius: 5px;
      background: #f4f5f7;
      padding: 8px 12px 8px 25px;
    }
    > span {
      top: 0;
      left: 0;
      z-index: 2;
      position: absolute;
      padding: 12px 0 12px 12px;
    }
  }
}
// .hidden {
//   display: none;
// }

// .fullName-class{
// width: 10%;
// }
// .jobTitle-class{
//   width: 12%;

// }
// .phone-class{
//   width: 10%;

// }

.deleteAction-class {
  width: 100px !important;
}
.overallSummary-class {
  width: 35%;
}
.overallSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actions-class {
  width: 5%;
}

.CrossButton {
  margin-top: 27px;
  /* border: none;
  background-color: #1d2541;
  padding: 9px 16px;
  .CrossICon{
    font-size: 18px;
    color: white;
    font-weight: bolder;
  } */
}
.imageremove {
  padding: 4px 8px;
  /* margin-left: 16.5pc;
    background-color: #1d2541;
    margin-top: 10px; */
  /* .CrossICon{
    font-size: 18px;
    color: white;
    font-weight: bolder;
  } */
}
.roleDropdown {
  margin-right: 10px;
}
.dropdown-toggle::after {
  display: contents;
}
.closureRequestCount,
.avgAcceptanceTime {
  h2 {
    span {
      display: inline-block !important;
    }
  }
}
.noDataFound {
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
.btncalander {
  background-color: #eee8e8;
  color: black;
  border-color: black;
  padding: 7px;
  border-radius: inherit;
}
.react-tel-input .form-control {
  font-size: 14px !important;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 100% !important;
  outline: none;
  padding: 18.5px 14px 18.5px 60px;
  transition:
    box-shadow ease 0.25s,
    border-color ease 0.25s;
  color: #686868 !important;
  font-weight: 500;
}
.react-tel-input :disabled {
  cursor: not-allowed;
  background-color: #f1f1f1 !important;
  color: #686868 !important;
  font-weight: 500;
}
.btn-clearDate {
  right: 8px;
  z-index: 2;
  width: 20px;
  bottom: 10px;
  height: 20px;
  line-height: 16px;
  position: absolute;
  border-radius: 50%;
  background-color: #1d2541;
  border-color: #1d2541;
  svg {
    width: 100% !important;
    height: auto;
  }
}
.btn-cross {
  width: 40px;
}
.main404 {
  padding-top: 74px;
  .topbar404 {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    position: fixed;
    padding: 20px 32px;
    justify-content: space-between;
  }
}
.content404 {
  strong {
    display: block;
    font-size: 64px;
    font-weight: 700;
    line-height: 75px;
    line-height: 1.2;
    padding-bottom: 2rem;
  }
  h2 {
    margin: 0 0 4rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.02em;
    font-size: var(--sub-title-404-font);
  }
}
.postionDetail {
  height: 40px;
  /* font-size: 12px; */
  font-size: 14px;
  font-weight: 600;
  color: #717171;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  padding: 0.5rem 1.5rem 0.5rem 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43' fill='%23717171'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position-x: 103%;
  background-position-y: 12px;
}

.statisticsBox {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
}

.accountManagerDashboard {
  border: #000;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
}
/* Add your own colors and styles */
table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #ddd; /* Add borders */
}

th,
td {
  padding: 8px;
  text-align: left;
}

.copyBtn {
  border: none;
  color: #06c0e7;
  background: #06c0e7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  height: 40px;
  width: 40px;
}
.copyBtn:hover {
  color: white !important;
  background-color: #2190a6;
}

.overallSummary {
  max-height: 67px;
  overflow: hidden;
  display: flex;
  .summarySection {
    //display: -webkit-box;
    //-webkit-line-clamp: 3; /* Number of lines to show */
    //-webkit-box-orient: vertical;
    //text-overflow: ellipsis;

    text-align: justify;
    margin-right: 20px;
    height: 49px;
  }
}
.copy-icon {
  height: 15px;
  width: 25px; /* Adjust the width as per your requirement */
  color: white;
}

.custom-tooltip .tooltip-inner {
  max-width: 600px;
  padding: 10px;
  /* Add other styles here */
}
/* styles.module.css */
.jobTitleJob {
  width: 200px; /* Adjust the width as needed */
}
.getJob {
  background-color: #1d2541;
  margin: 15px 20px;
}
.updateSkill {
  background: linear-gradient(45deg, rgb(51, 51, 51), rgb(81, 155, 254));

  &:hover {
    background-color: #1d2541;
  }
}

.hover-button {
  position: relative;
  background: linear-gradient(135deg, #47b6ec 0%, #4b3678 100%);
  overflow: hidden;
  padding: 12px 16px;
  font-size: 16px;
  span {
    position: relative;
    z-index: 1;
  }

  // Pseudo-element for hover gradient
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, #47b6ec, #4b3678);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover::before {
    opacity: 1;
  }
}

.generate-button {
  background-color: #ffff;
  position: relative;
  overflow: hidden;
  padding: 12px 16px;
  font-size: 16px;
  color: #000000;
  border: 1px solid #000000;

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
}
.generate-button-modal {
  background-color: #ffff;
  border: 1px solid #000000;
  color: #000000;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
}
.matchResourse {
  width: 32px;
  height: 15px;
  display: inline-block;
  border-radius: 8px;
  margin-right: 10px;
}
.columnsTop8R {
  padding-top: 20px; /* Adds external padding around the grid container */
  padding-bottom: 20px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(180px, 1fr)
  ); /* Creates a responsive grid layout */
  gap: 16px; /* Adds spacing between grid items */
  list-style-type: none; /* Removes default list styling */
  margin: 0; /* Adjusts the margin as needed */
}

.gridItem {
  background-color: #fafafa; /* Light grey background for each item */
  padding: 12px; /* Adds padding inside each grid item, you can adjust pb-3 in addition or remove it */
  border: 1px solid #dcdcdc; /* Adds a subtle border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a slight shadow for depth */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition:
    transform 0.2s,
    box-shadow 0.2s; /* Smooth transition for hover effects */
}

.gridItem:hover {
  transform: translateY(-2px); /* Slightly raises the item on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increases shadow for a lifting effect */
}

.gridItemQuestions {
  background-color: #fafafa; /* Light grey background for each item */
  padding: 12px; /* Adds padding inside each grid item, you can adjust pb-3 in addition or remove it */
  border: 1px solid #dcdcdc; /* Adds a subtle border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a slight shadow for depth */
  transition:
    transform 0.2s,
    box-shadow 0.2s; /* Smooth transition for hover effects */
}

.gridItemQuestions:hover {
  transform: translateY(-2px); /* Slightly raises the item on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increases shadow for a lifting effect */
}

.ai-buttons {
  width: 100%;
  justify-content: flex-end;
  margin-bottom: -42px;
  padding: 20px 25px;
  border-top: 1px solid var(--bs-border-color);
}
